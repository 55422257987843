export default {
  props: {
    show: {
      type: Boolean,
      default: true
    },
    menuCategories: {
      type: Array,
      default: null
    },
    menuImageLinks: {
      type: Array,
      default: null
    },
    initialImages: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      activeItem: null
    }
  },
  computed: {
    images () {
      if (!this.activeItem) {
        return this.initialImages || (this.categoryItems && this.categoryItems.length ? this.categoryItems[0].assets : [])
      }

      return this.activeItem.assets
    }
  },
  watch: {
    categoryItems () {
      this.activeItem = false
    }
  },
  methods: {
    contentClick (subContentType, itemID, options) {
      this.$analytics.selectContent('menu', subContentType, itemID, options)
    }
  }
}
